.c-contact {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    max-width: 900px;
}
.c-contact__image {
    width: 100%;
}
.c-contact__content {
    width: 50%;
    margin: 1rem 0;
    padding: 0 2rem;
    font-family: proxima-nova, sans-serif;
    font-size: 1rem;
    color: #333;
    box-sizing: border-box;

    a {
        color: #000;
        text-decoration: underline;

        &:hover,
        &:focus {
            color: #245473;
            text-decoration: underline;
            text-decoration-color: #000;
        }
    }
}
.c-contact__image-2 {
    width: 50%;
    margin-top: 1rem;
    object-fit: cover;
}

@media only screen and (max-width: 960px) {
    .c-contact {
        flex-direction: column;
    }
    .c-contact__content {
        width: 100%;
        margin: 1rem 0 0 0;
    }
    .c-contact__image-2 {
        width: 100%;
        height:  calc((100vw - 17rem - 6rem) * 6 / 7);
        object-position: top center;
    }
}

@media only screen and (max-width: 800px) {
    .c-contact__image-2 {
        height: calc((100vw - 2rem) * 6 / 7);
    }
}