@function min($numbers...) {
    @return m#{i}n(#{$numbers});
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-size: 18px;
}

.o-title {
    margin: 0;
    font-family: raleway, sans-serif;
    text-transform: uppercase;
}
.o-title--primary {
    font-size: 3rem;
    font-weight: 100;
    line-height: 1;
    text-transform: uppercase; 
    text-align: center;
}

.slbElement {
    .slbOverlay {
        color: #000;
        background-color: #fff;
        animation: slbOverlay2 0.5s;
        opacity: 0.9;
    }
    
    .slbCloseBtn {
        font-family: raleway, sans-serif;
        font-weight: 300;
        color: #000;
    }
    .slbArrows {
        top: auto;
        bottom: 2rem;
        left: 50%;
        height: 2rem;
        transform: translateX(-50%);
        max-width: 10rem;
        color: #000;
    }
    .slbArrow {
        top: 0;
        height: 2rem;
        margin: 0;
        opacity: 1;
    }
    .slbArrow.prev:before {
        border-right-color: #000;
    }
    .slbArrow.next:before {
        border-left-color: #000;
    }
    .slbImageWrap:after {
        display: none;
    }
}
.c-404__image {
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: cover;
}
.c-404__text {
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    margin: 0;
    padding: 0 1rem;
    font-family: raleway, sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    text-shadow: 1px 1px 2px rgba(255,255,255,0.2);
    color: #000;
}

@keyframes slbOverlay2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9; 
  }
}

@media only screen and (max-width: 768px) {
    .slbElement {
        .slbArrow {
            height: 2rem;
        }
    }
}