.c-big-image {
    width: 100%;
    max-width: 900px;
    font-family: proxima-nova, sans-serif;
}
.c-big-image__figure {
    width: 100%;
    margin: 0;
}
.c-big-image__image {
    width: 100%;
    margin: 0;
}
.c-big-image__caption {
    width: auto;
    margin: 0.5em 1em;
    font-size: 0.85rem;
    text-align: right;
}
.c-big-image__blurb {
    width: 100%;
    margin-top: 2rem;
    font-size: 1rem;
    color: #333;

    a {
        color: #000;
        text-decoration: underline;

        &:hover,
        &:focus {
            color: #245473;
            text-decoration: underline;
            text-decoration-color: #000;
        }
    }
}