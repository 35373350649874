.c-full-page {
    position: relative;
    width: 100%;
    height: calc(100vh - 6rem);
}
.c-full-page__img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 800px) {
    .c-full-page {
        height: calc(100vh - 2rem - 2rem - 2rem - (1rem * 3) - (0.25rem * 6) - (1.2rem * 1.5) - (2rem * 0.9));
    }
}