$gutter-width : 48px;
$content-width: calc(100vw - 17rem);

.c-packed-grid {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    // grid-template-columns: repeat(12, 1fr);
    // grid-auto-flow: row dense;
    // grid-auto-columns: 5rem;
    // grid-auto-rows: calc((#{$content-width} - #{$gutter-width} * 11) / 12 * 3 / 3);
    width: 100%;
    grid-gap: $gutter-width;
    gap: $gutter-width;
}
.c-packed-grid__item-wrapper {
    position: relative;
    display: inline-block;
    grid-column: span 3; 
    width: calc((100% - #{$gutter-width * 2}) / 3);
    padding: 0;
    background-color: #f6f6f6;
    // transition: transform 250ms ease-out;
    overflow: hidden;
}
.c-packed-grid__item {
    display: block;
    width: 100%;
    height: 0;
}
.c-packed-grid__item-wrapper--double-wide { 
    grid-column: span 6;
    width: calc((100% - #{$gutter-width / 2}) / 3 * 2);
}
.c-packed-grid__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (min-width: 1601px) {
    .c-packed-grid--4-col .c-packed-grid__item-wrapper {
        width: calc((100% - #{$gutter-width * 3}) / 4);
    }
    .c-packed-grid--4-col .c-packed-grid__item-wrapper--double-wide {
        width: calc((100% - #{$gutter-width}) / 2);
    }
}

@media only screen and (max-width: 1600px) {
    .c-packed-grid__item-wrapper {
        float: left;
        width: calc((100% - #{$gutter-width * 2}) / 3);
    }
    .c-packed-grid__item-wrapper--double-wide {
        float: left;
        width: calc((100% - #{$gutter-width / 2}) / 3 * 2);
    }
}

@media only screen and (max-width: 1100px) {
    .c-packed-grid__item-wrapper {
        float: left;
        width: calc((100% - #{$gutter-width}) / 2);
    }
    .c-packed-grid__item-wrapper--double-wide {
        float: left;
        width: 100%;
    }
}

@media only screen and (max-width: 340px) {
    .c-packed-grid__item-wrapper {
        float: left;
        width: 100%;
    }
}