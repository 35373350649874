.c-social-icons {
    display: flex;
    margin-top: 1rem;
}
.c-social-icons__item {
    display: block;
    margin-left: 0.5rem;
    font-size: 0;
    text-decoration: none;
    color: #666;

    &:first-child {
        margin-left: 0;
    }
    &::before {
        content: '';
        font-family: 'Font Awesome 5 Brands';
        font-weight: 400;
        font-size: 1.25rem;
    }
    &:hover,
    &:focus {
        color: #222;
    }
}
.c-social-icons__item--instagram {
    &::before {
        content: '\f16d';
    }
}
.c-social-icons__item--twitter {
    &::before {
        content: '\f099';
    }
}

@media only screen and (max-width: 800px) {
    .c-social-icons {
        justify-content: center;
        margin-top: 0.25rem;
    }
}