.c-main-nav {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}
.c-main-nav__item {
    display: block;
    margin: 0;
    font-family: raleway, sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
    text-transform: uppercase;
    color: #666;

    &.current-menu-item {
        font-weight: 600;
        color: #333;
    }
}
.c-main-nav__link {
    text-decoration: none;
    transition: color 150ms ease-out;

    &:hover,
    &:focus {
        color: #000;
    }
}

@media only screen and (max-width: 800px) {
    .c-main-nav {
        flex-direction: column;
        align-items: center;
    }
    .c-main-nav__item {
        margin: 0.25rem;
    }
}