.l-sidebar {
    display: flex;
}

.l-sidebar__sidebar {
    flex: 0 0 auto;
    width: 17rem;
    background-color: #fff;
    // box-shadow: inset -25px 0 20px -20px rgba(0,0,0,0.05);
}
.l-sidebar__content {
    flex: auto;
    width: calc(100% - 17rem);
    max-width: 1800px;
    padding: 3rem;
}
.c-sidebar {
    padding: 3rem 0 3rem 3rem;
    text-align: left; 
}
.c-sidebar__home-link {
    display: block;
    text-decoration: none;
}
.c-sidebar__title {
    font-size: 2rem;
    font-weight: 200;
    line-height: 0.9;
    color: #111;
}
.c-sidebar__subtitle {
    margin: 0;
    font-family: raleway, sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    text-transform: uppercase;
    color: #666;
}
.c-sidebar__page-title {
    margin: 1rem 0 0 0;
    font-family: proxima-nova, sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 0.03em;
    color: #444;
}
.c-sidebar__utility-links {
    margin-top: 4rem;
    font-family: proxima-nova, sans-serif;
    font-size: 0.9rem;
    color: #666;
}
.c-sidebar__utility-links__item {
    display: block;
    margin-top: 1em;
    font-family: proxima-nova, sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    text-decoration: none;
    color: #666;

    &:first-child {
        margin-top: 0;
    }
    &::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin-left: 1em;
        margin-top: -0.2em;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 6px;
        border-color: transparent transparent transparent #666;
    }
}
.c-sidebar__utility-links__link {
    color: #666;

    &:hover,
    &:focus {
        color: #000;
    }
}

@media only screen and (max-width: 800px) {
    .l-sidebar {
        flex-direction: column;
    }
    .l-sidebar__sidebar {
        width: 100%;
        padding: 1rem;
    }
    .l-sidebar__content {
        width: 100%;
        padding: 1rem;
    }
    .c-sidebar__home-link {
        text-align: center;
    }
    .c-sidebar__utility-links {
        width: 14rem;
        margin: 2rem auto 0;
        text-align: center;
    }
}

@media only screen and (max-width: 360px) {
    .c-sidebar__utility-links {
        width: 100%;
    }
}